@import "src/assets/scss/Shared.scss";

.loader {
  position: absolute;
  width: 100px;
  height: 100px;
  top: rem(250);
  left: 50%;
  margin: -50px;
  animation: rotating 0.5s linear infinite;
  transform-origin: 50% 50%;
}
