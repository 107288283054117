html,
body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  background-color: $c-grey-light;
}

* {
  box-sizing: border-box;
}

.lazyload-wrapper {
  height: 100%;
}
