a {
  color: $c-green;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: $c-green;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
