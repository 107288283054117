.container {
  @include container;

  &--page {
    min-height: rem(800);
    padding-bottom: rem(50);
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;

  &__wrapper {
    @media screen and (min-width: $bp-xl) {
      @include container;
    }
  }

  @media screen and (min-width: $bp-xl) {
    margin: 0 0 rem(20) -#{rem(20)};
    flex-wrap: nowrap;
  }

  @media screen and (min-width: $bp-xl) {
    margin: 0 0 rem(50) -#{rem(50)};
  }

  &__half {
    display: block;
    width: 100%;

    &--column {
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: $bp-xl) {
      margin-left: rem(20);
      width: 50%;
    }

    @media screen and (min-width: $bp-xl) {
      margin-left: rem(50);
    }
  }
}
