// pallete
$c-white: #ffffff;

$c-grey-lightest: #fbfbfb;
$c-grey-light: #f2f2f2;
$c-grey-medium: #e2e2e2;
$c-grey-dark: #595959;
$c-grey-darkest: #979797;

$c-green: #82987c;
$c-green-dark: #667762;

// brand
$c-primary: $c-green-dark;

// uses
$c-text: $c-grey-dark;
