input {
  &[type="text"] {
    padding: rem(10);
    font-size: rem(18);
    height: rem(50);
    width: 100%;
    margin: 0 !important;

    &::placeholder {
      color: #b7b7b7;
    }
  }
}
