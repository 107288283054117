@import "src/assets/scss/Shared.scss";

.article-steps {
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    counter-reset: counter;
  }

  li {
    margin: rem(70) 0 0;
    counter-increment: counter;
    position: relative;
    padding: 0;
    max-width: rem(600);

    @media screen and (min-width: $bp-s) {
      margin: 0;
      padding: 0 0 0 rem(55);
      top: -#{rem(6)};
    }

    &:before {
      content: "0" counter(counter);
      position: absolute;
      top: -#{rem(45)};
      left: 0;
      font-size: rem(30);
      font-family: $ff-primary;
      font-weight: bold;
      color: $c-green;

      @media screen and (min-width: $bp-s) {
        top: -#{rem(6)};
        max-width: rem(600);
      }
    }

    &:nth-child(n + 10):before {
      content: counter(counter);
    }

    p {
      margin: 0;
    }
  }

  li + li {
    margin-top: rem(80);

    @media screen and (min-width: $bp-s) {
      margin-top: rem(50);
    }
  }
}
