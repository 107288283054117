body {
  font-family: $ff-default;
  color: $c-text;
}

h1,
h2,
h3 {
  font-family: $ff-primary;
  padding: 0;
  margin: rem(30) 0;
  font-weight: bold;
  color: $c-green-dark;
}

h1 {
  font-size: rem(32);
  line-height: 1.3;
  margin: 0 0 rem(40);

  @media screen and (min-width: $bp-m) {
    font-size: rem(48);
  }
}

h2 {
  font-size: rem(32);

  @media screen and (min-width: $bp-m-l) {
    font-size: rem(38);
  }
}

h3 {
  font-size: rem(24);

  @media screen and (min-width: $bp-m-l) {
    font-size: rem(26);
  }
}

p {
  line-height: 1.6;
}

.text-capitalise {
  text-transform: capitalize;
}
