@import "src/assets/scss/Shared.scss";

.article-filters {
  &__container {
    position: relative;
    margin-bottom: 30px;

    li:hover {
      background-color: $c-grey-light;
    }
  }

  &__link {
    font-size: rem(14);
    margin: 10px 0;
    color: $c-text;
    text-decoration: underline;

    &:hover {
      color: $c-primary;
    }
  }
}
