@import "src/assets/scss/Shared.scss";

.article-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media screen and (min-width: $bp-s) {
    margin-right: -#{rem(15)};
  }

  @media screen and (min-width: $bp-l) {
    margin-right: -#{rem(30)};
  }

  @media screen and (min-width: $bp-xl) {
    margin-right: -#{rem(15)};
  }
}
