@import "src/assets/scss/Shared.scss";

.shops-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media screen and (min-width: $bp-m) {
    margin-right: -#{rem(20)};
  }
}
