@import "src/assets/scss/Shared.scss";

.month-list-item {
  margin: rem(20) 0 0;
  padding: 0;
  list-style: none;
  background: $c-white;

  &__toggle {
    position: relative;
    display: block;
    padding: rem(20) rem(15) rem(20) rem(50);
    width: 100%;
    margin: 0;
    background: $c-white;
    font-weight: bold;
    font-size: rem(22);
    font-family: $ff-primary;
    text-align: left;
    color: $c-primary;
    border: none;
    appearance: none;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
      background: $c-grey-lightest;
    }

    &:before,
    &:after {
      content: "";
      background: $c-primary;
      position: absolute;
    }

    &:before {
      width: rem(21);
      height: rem(3);
      left: rem(15);
      top: rem(34);
    }
    &:after {
      height: rem(21);
      width: rem(3);
      left: rem(24);
      top: rem(25);
      display: block;
    }

    &[aria-expanded="true"]:after {
      display: none;
    }
  }

  &__expander {
    height: 0;
    overflow: hidden;

    &-inner {
      padding: rem(10) rem(15) rem(30) rem(34);

      &--empty {
        padding-left: rem(50);
      }
    }
    &--open {
      height: auto;
    }

    p {
      margin: 0;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0 rem(16);
      list-style: circle;
      margin-bottom: -#{rem(10)};
      column-count: 1;

      @media screen and (min-width: 380px) {
        column-count: 2;
      }

      @media screen and (min-width: $bp-m) {
        column-count: 3;
      }
      @media screen and (min-width: $bp-l) {
        column-count: 4;
      }
    }

    li {
      margin-bottom: rem(10);

      @media screen and (min-width: 380px) {
        padding-right: rem(20);
      }
    }
  }
}
