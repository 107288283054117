@import "src/assets/scss/Shared.scss";

.shop-card {
  display: flex;
  flex-basis: calc(100%);
  padding: 0 0 rem(20);

  @media screen and (min-width: $bp-m) {
    flex-basis: calc(50%);
    padding: 0 rem(20) rem(20) 0;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    background-color: $c-white;
    height: 100%;
    text-decoration: none;
    padding: rem(20) rem(15);
  }

  &__title {
    font-size: rem(22);
    margin: 0;
    color: $c-primary;
    font-family: $ff-primary;
    line-height: 1.3;
  }

  &__description {
    color: $c-text;
    flex-grow: 1;
  }

  &__links {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
