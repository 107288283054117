@import "src/assets/scss/Shared.scss";

.season-list,
.month-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.season-list {
  &__title {
    margin: 0;
    padding: 0;
  }

  &__item {
    & + & {
      margin-top: rem(40);
    }
  }
}
