$base-text-size: 16px;

@function rem($value: $base-text-size, $type: string) {
  $remSize: $value / ($base-text-size / 1px);

  @if ($type == number) {
    @return $remSize * 1rem;
  }

  @return #{$remSize}rem;
}

@mixin position($position, $top, $right, $bottom, $left) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin absolutely-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin container {
  max-width: rem(1200);
  margin: 0 auto;
  padding: 0 rem(20);
  overflow-x: hidden;
}
