@import "src/assets/scss/Shared.scss";

.page-top {
  color: $c-white;
  padding: rem(40) 0 rem(50);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__links-toggle {
    display: block;
    -webkit-tap-highlight-color: transparent;

    @media screen and (min-width: $bp-m) {
      display: none;
    }

    &-icon {
      padding: rem(10);
      transition: all 0.2s ease-in-out;
      will-change: transition;
      filter: invert(44%) sepia(28%) saturate(258%) hue-rotate(63deg)
        brightness(93%) contrast(84%);
    }
    &:hover &-icon {
      filter: invert(32%) sepia(14%) saturate(450%) hue-rotate(62deg)
        brightness(98%) contrast(90%);
    }
  }

  &__links-wrapper {
    @media screen and (max-width: $bp-mobile) {
      width: rem(300);
      height: 100%;
      position: absolute;
      top: 0;
      right: -#{rem(300)};
      background: $c-white;
      z-index: 10;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
      padding: rem(50) rem(15);
      transition: all 0.1s ease-in-out;
    }

    &--open {
      @media screen and (max-width: $bp-mobile) {
        right: 0;
      }
    }
  }

  &__links-wrapper-close {
    display: block;
    appearance: none;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    @media screen and (min-width: $bp-m) {
      display: none;
    }

    &-icon {
      position: absolute;
      top: rem(15);
      right: rem(15);
      width: rem(30);
      height: rem(30);
      transition: all 0.2s ease-in-out;
      will-change: transition;
      filter: invert(44%) sepia(28%) saturate(258%) hue-rotate(63deg)
        brightness(93%) contrast(84%);
      padding: 5px;
    }

    &:hover &-icon,
    &:focus &-icon {
      filter: invert(32%) sepia(14%) saturate(450%) hue-rotate(62deg)
        brightness(98%) contrast(90%);
    }
    &:focus &-icon {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  &__links-link {
    display: block;
    padding: rem(15);
    font-family: $ff-primary;
    font-size: rem(18);
    color: $c-text;
    -webkit-tap-highlight-color: transparent;

    @media screen and (min-width: $bp-m) {
      display: inline-block;
      padding: rem(10) rem(12);
    }

    &--active {
      color: $c-primary;
      pointer-events: none;
      text-decoration: underline;
    }

    & + & {
      @media screen and (min-width: $bp-m) {
        border-left: 1px solid $c-grey-dark;
      }
    }
  }

  &__home-link {
    max-height: rem(75);
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
  }
}
