@import "src/assets/scss/Shared.scss";

.article-ingredients {
  padding: 0 rem(20) rem(10);
  flex-grow: 1;

  @media screen and (min-width: $bp-xl) {
    background: $c-white;
    padding: rem(40) rem(30) rem(20);
  }

  &__title {
    @media screen and (min-width: $bp-xl) {
      font-size: rem(28);
      margin: 0 0 rem(30);
    }
  }

  * + h3 {
    margin: rem(20) 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    column-count: 1;
    margin-bottom: rem(20);

    @media screen and (min-width: $bp-s) {
      column-count: 2;
    }
  }

  li {
    display: block;
    margin: 0 0 rem(20);

    @media screen and (min-width: $bp-s) {
      padding-right: rem(20);
    }
  }

  p {
    margin: 0;
  }
}
