@import "src/assets/scss/Shared.scss";

.logo {
  font-family: $ff-primary;
  text-decoration: none;
  fill: $c-primary;
  width: rem(75);
  height: rem(75);
  color: $c-white;
  background-color: $c-primary;
  position: relative;
  display: inline-block;
  transition: all 0.2s ease-in-out;

  &--grey {
    background-color: darken($c-grey-medium, 5%);
    color: $c-grey-medium;

    a:hover &,
    a:focus & {
      background-color: darken($c-grey-medium, 15%);
    }
  }

  &-text {
    font-size: rem(46);
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  a > & {
    &:hover,
    &:focus {
      background-color: darken($c-primary, 10%);
      text-decoration: none;
    }
  }
}
