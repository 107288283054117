@import "src/assets/scss/Shared.scss";

.page-header {
  background-color: $c-white;
  position: relative;
  background: $c-grey-light;

  &__top {
    @media screen and (min-width: $bp-xl) {
      max-width: 50%;
    }
  }

  &__title,
  &__intro-text {
    display: block;
  }

  &__title {
    margin-bottom: rem(30);
  }

  &__intro-text {
    max-width: rem(800);
    margin: 0;
    margin-bottom: rem(40);
  }
}
