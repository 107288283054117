@import "src/assets/scss/Shared.scss";

.article-card {
  flex-basis: calc(100%);
  padding: 0 0 rem(20);
  overflow: hidden;

  @media screen and (min-width: $bp-s) {
    flex-basis: calc(50%);
    padding: 0 rem(20) rem(20) 0;
  }

  @media screen and (min-width: $bp-l) {
    flex-basis: calc(33%);
  }

  @media screen and (min-width: $bp-xl) {
    flex-basis: calc(25%);
  }

  &__link {
    display: block;
    background-color: $c-white;
    height: 100%;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__top {
    overflow: hidden;
    position: relative;
  }

  &__title {
    font-size: rem(16);
    margin: 0;
    color: $c-text;
    font-family: $ff-default;
    font-weight: normal;
    line-height: 1.3;

    .article-card__link:hover & {
      color: $c-primary;
      text-decoration: underline;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: rem(250);
    object-fit: cover;
    transition: ease-in-out 0.2s;

    .article-card__link:hover & {
      transform: scale(1.1);
    }
  }

  &__time {
    display: block;
    font-size: rem(12);
    margin-bottom: rem(5);
    color: $c-green;

    .article-card__link:hover & {
      color: $c-green;
    }
  }

  &__bottom {
    padding: rem(20) rem(15);
  }
}
