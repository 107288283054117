@import "src/assets/scss/Shared.scss";

.shop-link {
  &__item {
    padding-left: rem(25);
    position: relative;

    & + & {
      margin-top: rem(10);
    }
  }
  &__icon {
    fill: $c-primary;
    position: absolute;

    &--website {
      width: rem(14);
      height: rem(21);
      left: 1px;
    }

    &--location {
      width: rem(18);
      height: rem(18);
      left: -1px;
      top: 2px;
    }
  }
}
