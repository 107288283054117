@import "src/assets/scss/Shared.scss";

.article-info {
  display: flex;
  flex-wrap: wrap;
  background-color: $c-white;
  font-family: $ff-primary;
  align-items: center;
  margin-top: -#{rem(2)};

  @media screen and (min-width: $bp-xl) {
    margin-bottom: rem(20);
    margin-top: 0;
  }

  &__image {
    width: 100%;
    max-height: rem(400);
    object-fit: cover;

    @media screen and (min-width: $bp-xl) {
      height: 100%;
      max-height: 100%;
    }
  }

  &__block {
    text-align: center;
    font-size: rem(14);
    flex-grow: 1;
    position: relative;
    min-width: rem(110);
  }

  &__block--split {
    display: flex;
    align-items: center;
    height: rem(94);
    justify-content: space-evenly;

    > div {
      position: relative;
      min-width: rem(100);
      height: 100%;
    }
  }

  &__block--cuisine {
    padding: rem(20);
    font-size: rem(18);
    background: $c-green;
    color: $c-white;
    font-weight: 400;
    font-style: italic;
    padding: rem(35);

    &:hover {
      background: $c-green-dark;
      text-decoration: none;
    }
  }

  &__block-number,
  &__block-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__block-number {
    font-size: rem(34);
    color: $c-green;
    top: rem(12);
  }

  &__block-label {
    top: rem(57);
  }
}
