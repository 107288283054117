@import "src/assets/scss/Shared.scss";

.image-placeholder {
  background: $c-grey-medium;
  width: 100%;
  height: 100%;
  position: relative;

  &--thumbnail {
    height: rem(250);
  }

  &--article {
    min-height: 300px;
    z-index: 1;

    @media screen and (min-width: $bp-xl) {
      min-height: auto;
    }
  }

  .logo {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
